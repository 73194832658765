<template>
  <div class="body-bg">
    <van-nav-bar
      title="手机快捷登录"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>
    <div class="container">
      <div class="login-body">
        <div class="login-form">
          <van-cell-group>
            <van-field
              v-model="phone"
              center
              clearable
              placeholder="请输入手机号码"
              use-button-slot
            >
              <template #button>
                <van-button
                  v-if="isCodeSend == false"
                  size="small"
                  type="primary"
                  color="#F3C301"
                  @click="sendSMS"
                  >发送验证码</van-button
                >
                <van-button v-else size="small" type="primary" color="#F3C301">
                  <van-count-down :time="time" format="ss" @finish="setSended"/>
                  </van-button>
              </template>
            </van-field>
            <van-field v-model="code" placeholder="请输入验证码" />
            <van-cell>
              <div slot="title">
                <van-checkbox
                  icon-size="16"
                  v-model="protocol"
                  checked-color="#F3C301"
                >
                  注册即代表<span bindtap="gotoProtocol" class="protocol"
                    >《用户服务协议》
                  </span>
                </van-checkbox>
              </div>
            </van-cell>
            <van-cell>
              <van-button
                from-type="submit"
                round
                block
                type="info"
                color="#F3C301"
                :disabled="!protocol"
                @click="phoneLogin"
                >登录</van-button
              >
            </van-cell>
          </van-cell-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/api/user";
import { Toast } from "vant";
export default {
  data() {
    return {
      protocol: false,
      phone: "",
      code: "",
      time: 60*1000,
      token: "",
      isCodeSend: false,
    };
  },
  async mounted() {
    this.token = this.$route.params.token;
    this.$cookie.set("token", this.token);
  },
  methods: {
    async sendSMS() {
      if(this.phone == "") {
        Toast("手机号不能为空");
        return;
      }
      var params = {
        type: 2,
        phone: this.phone,
      };
      const resp = await UserService.sendCode(this, params);
      if (resp.data.code == 10000) {
        console.log("234")
        Toast("验证码已发送,请注意查收!");
      }else{
        //Toast(resp.data.msg);
        Toast("短信发送错误,请稍后重试!");
      }
      this.isCodeSend = true;
    },
    setSended(){
      this.isCodeSend = true
    },
    async phoneLogin() {
      if(this.phone == "") {
        Toast("手机号不能为空");
        return;
      }
      if(this.code == "") {
        Toast("验证码不能为空");
        return;
      }
      var params = {
        //token: this.$cookie.get("token"),
        token: this.token,
        phone: this.phone,
        code: this.code,
        type: 2,
      };
      console.log(params);
      const resp = await UserService.loginAsPhone(this, params);
      if (resp.data.code == 10000) {
        console.log(resp);
        //登录成功
        this.$cookie.set("isLogin", true);
        var params1 = {
          //token: this.$cookie.get("token"),
          token: this.token,
        };
        const resp1 = await UserService.staffInfo(this, params1);
        if (resp1.data.code == 10000) {
          this.$router.push("/");
        }
      }

      this.$router.push("/index");
    },
    goBack: function() {
      this.$router.go(-1);
    },
    goHome: function() {
      this.$router.push("/index");
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
}
.login-body {
  padding: 60px 16px 40px 16px;
}
.login-body .title {
  font-size: 24px;
  color: #333333;
}
.login-body .login-form {
  width: 100%;
  height: 50px;
  margin: 60px auto;
}
</style>
